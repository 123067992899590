<template>
  <div class="container">
    <van-sticky>
      <van-nav-bar title="我的课程" left-arrow @click-left="$router.back()" />
    </van-sticky>

    <van-list
      v-model="upLoading"
      :finished="finished"
      @load="onLoad"
      finished-text="没有更多了"
    >
      <van-cell-group>
        <!-- art_id有可能是大数字 超过一定长度会转成 BigNumber  要toString一下 -->
        <van-cell
          @click="toDetail(item.itemId.toString())"
          v-for="item in articles"
          :key="item.itemId.toString()"
        >
          <van-col span="6"
            ><van-image
              lazy-load
              class="w100"
              fit="contain"
              style="max-height: 200px"
              :src="item.imageUrl"
            />
          </van-col>
          <van-col span="18" align="bottom">
            <div
              style="display: table-cell; vertical-align: middle"
              class="zntitle van-multi-ellipsis--l3"
            >
              <h3 style="display: inline-block">{{ item.itemName }}</h3>
            </div>
            <div style="float: right">{{ item.createTime }}</div>
          </van-col>
        </van-cell>
      </van-cell-group>
    </van-list>
  </div>
</template>

<script>
import { getBrowsingLogTypeList } from "@/api/browsingLog";

//const key = "zn-history";
export default {
  name: "zncourselist",
  data() {
    return {

      keyword: "",
      upLoading: false,
      finished: false,
      articles: [],
      pagePara: {
        current: 1,
        pageSize: 10,
        filterData: {
            itemType: 3,
        },
      },
    };
  },
  components: {},
  created() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.query != from.query) {
        this.initData();
        this.onSearch();
      }
    },
  },
  mounted() {},

  methods: {
    async initData() {
      if(this.$route.query.id!=undefined)
      {
     this.pagePara.filterData.schoolClass= this.$route.query.id;
      this.pagePara.filterData.schoolClass2 =0;
      }
    },
   

    onSearch() {
      this.articles = [];
      this.pagePara.current = 1;
      this.pagePara.pageSize = 10;
      this.onLoad();
    },
    toDetail(id) {
      this.$router.push({
        path: "/course/detail",
        query: { para: id, type: this.pagePara.filterData.productClass },
      });
    },

    async onLoad() {


      let aresult = await getBrowsingLogTypeList({ ...this.pagePara });

      this.articles.push(...aresult.data.data);
      this.upLoading = false;
      if (this.pagePara.current * this.pagePara.pageSize < aresult.data.total) {
        this.pagePara.current++;
         this.finished = false;
      } else {
        this.finished = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.w100 {
  /deep/ img {
    width: 100%;
    max-height: 200px;
  }
}
.zntitle {
  height: 80px;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.detail {
  padding: 46px 10px 44px;
  .title {
    font-size: 18px;
    line-height: 2;
  }
  .zan {
    text-align: center;
    padding: 10px 0;
    .active {
      border-color: red;
      color: red;
    }
  }
  .author {
    padding: 10px 0;
    display: flex;
    position: sticky;
    background-color: #fff;
    top: 46px;
    z-index: 2;
    .text {
      flex: 1;
      padding-left: 10px;
      line-height: 1.5;
      .name {
        font-size: 14px;
        margin: 0;
      }
      .time {
        margin: 0;
        font-size: 12px;
        color: #999;
      }
    }
  }
  .content {
    padding: 20px 0;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;
    /deep/ img {
      max-width: 100%;
      background: #f9f9f9;
    }
    /deep/ code {
      white-space: pre-wrap;
    }
  }
}
</style>
